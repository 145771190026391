<template>
	<section class="search-place search-place--list">
		<div class="search-place__container">
			<div class="search-place__wrapper">
        <div class="search-place__content search-place-content fullscreen">
          <div class="search-place__title">
            <div class="search-place__title-text">
              <div class="search-place__title-container">
                <h2 class="section-title">Избранное</h2>
              </div>
            </div>
          </div>
          <div
            class="search-place__scroll"
            v-if="!isEmpty"
            ref="searchPlaceList"
          >
            <ul class="search-place__cards">
	            <li
			            v-for="place in places.items"
			            :key="place.id"

	            >
		            <SearchCard
				            class="fullscreen"
				            :place="place"
				            @updatePlace="updatePlace"
		            />
	            </li>

            </ul>
            <div class="search-place__pagination">
              <tk-pagination
                :content="places?.meta"
                :offset-page="query.page"
                @next="changePage"
              />
            </div>
          </div>
          <p v-else class="search-place__empty">
            У вас пока не добавлено объявлоений в избранное.
            <br><br>
            Добавляйте в избранное жилье, которое вам нравится. Примите решение,
            выбрав из нескольких понравившихся вариантов.
          </p>
        </div>
			</div>
		</div>
	</section>
</template>

<script>
import modals from '@/constants/modals'

import Sorting from '@/components/Sorting'
import SearchPlace from '@/components/cards/Search'
import SearchCard from '@/components/cards/Search'

import { GET } from '@/constants/store/favourites/actions'


// Refactoring class and styles

export default {
	name: 'Favourites',
	components: { SearchPlace, Sorting, SearchCard },
	data() {
		return {
			searchPlaceList: null,
			loading: true
		}
	},
	async beforeMount() {
		this.loading = true
		await this.get(this.payloadForSearch)
		await this.$nextTick(() => {
      this.loading = false
    })
	},
	computed: {
		query() {
			return this.$route.query
		},
		favourites() {
			return this.$store.state.favourites
		},
    payloadForSearch() {
      const payload = { ...this.$route.query }

      if (payload.options) {
        payload.options = JSON.parse(payload.options)
      }

      payload.per_page = 10
      return payload
    },
		places() {
      this.favourites?.content?.items?.forEach((place) => {
        place.is_favorite = true
      })
			return this.favourites.content
		},
		isEmpty() {
			return !this.places.items || !this.places.items.length
		},
	},
	methods: {
		showPlaceFilter() {
			this.$store.commit('showModal', {
				name: modals.PLACE_FILTER,
				props: { is_user_favorites: 1 },
				isShow: true
			})
		},
		async setFavourite(id) {
			const response = await this.$api.favorites.create({ places: id })

			if (response.status) {
				this.$store.commit('showNotification', {
					type: 'success',
					text: 'Успешно добавлено в избранное'
				})
			} else {
				this.$store.commit('showNotification', {
					type: 'error',
					text: 'Ошибка добавления в избранное'
				})
			}
		},
		async updatePlace() {
      await this.get(this.payloadForSearch)
		},
		async get(payload) {
			await this.$store.dispatch(GET, payload)
		},
    async changePage(page) {
      await this.get({ ...this.payloadForSearch, page })
    },
	}
}
</script>
